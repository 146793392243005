<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" :value="dialog" max-width="420" @input="$emit('dialog')">
    <v-card :class="$vuetify.breakpoint.xsOnly ? 'fullscreen' : ''" class="activation-request">
      <v-btn class="activation-request__close" icon rounded @click="close">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
      <h2 class="activation-request__title">{{ $t("login.invite.newActivationCodeRequest") }}</h2>
      <p class="activation-request__text">
        {{ $t("login.invite.requestCodeText1") }}
      </p>
      <p class="activation-request__text">
        {{ $t("login.invite.requestCodeText2") }}
      </p>
      <BaseInput
        :placeholder="$t('login.invite.enterPin')"
        :value="invitePin"
        fieldName="invitePin"
        @change="onInvitePinChange"
      />
      <v-card-actions class="activation-request__actions pa-0">
        <PrimaryButton
          :disabled="!invitePin"
          :text="$t('login.invite.generate')"
          className="primary-btn__blue mt-3"
          size="large"
          @onClick="generateCode"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "pinia";

import BaseInput from "@/components/uikit/BaseInput";
import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useInvitesStore } from "@/pinia-store/invites";

export default {
  name: "ActivationRequest",
  components: {
    BaseInput,
    PrimaryButton,
  },

  props: ["dialog"],
  computed: {
    ...mapState(useInvitesStore, ["invitePin"]),
  },
  methods: {
    ...mapActions(useInvitesStore, ["setInvitePin"]),
    ...mapActions(useInvitesStore, ["getActivationCode"]),
    close() {
      this.$emit("dialog");
      this.$emit("close");
    },
    onInvitePinChange(value) {
      this.setInvitePin(value);
    },
    async generateCode() {
      try {
        await this.getActivationCode(this.invitePin);
        this.$emit("openActivationCodeDialog");
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-request {
  position: relative;
  padding: 30px;

  &.fullscreen {
    padding: 30px !important;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 13px;
    border: 1px solid #e7e8f2;
  }

  &__title {
    font-size: 25px;
    color: #25233a;
    margin-top: 10px;
  }

  &__text {
    font-size: 14px;
    color: #33343d;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
