<template>
  <v-dialog :fullscreen="$vuetify.breakpoint.xsOnly" :value="dialog" max-width="420" @input="$emit('dialog')">
    <v-card :class="$vuetify.breakpoint.xsOnly ? 'fullscreen' : ''" class="activation-code">
      <v-btn class="activation-code__close" icon rounded @click="close">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
      <h2 class="activation-code__title">{{ $t("login.invite.newActivationCode") }}</h2>
      <p class="activation-code__text">{{ $t("login.invite.newActivationCodeText1") }}</p>
      <div class="activation-code__field" @click="copyCode">
        <b class="mb-0">{{ activationCode }}</b>
        <img alt="copy" src="@/assets/icons/icon_copy.svg" />
      </div>
      <div class="activation-code__field mt-3" @click="openStartPage">
        <p class="mb-0">{{ startUrl }}</p>
        <img alt="enter" src="@/assets/icons/icon_activate_account_at.svg" />
      </div>
      <v-card-actions class="activation-code__actions pa-0">
        <PrimaryButton
          :text="$t('login.invite.generateAnother')"
          className="primary-btn__blue mt-3"
          size="large"
          @onClick="generateCode"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "pinia";

import PrimaryButton from "@/components/uikit/PrimaryButton";
import { snackBarEventBus, snackBarEventName } from "@/eventBuses/snackBar.eventBus";
import { useInvitesStore } from "@/pinia-store/invites";

export default {
  name: "ActivationCode",
  props: ["dialog"],
  components: { PrimaryButton },
  computed: {
    ...mapState(useInvitesStore, ["invitePin", "activationCode"]),
    startUrl() {
      return window.location.href;
    },
  },
  methods: {
    ...mapActions(useInvitesStore, ["getActivationCode"]),
    copyCode() {
      navigator.clipboard.writeText(this.activationCode);
      snackBarEventBus.$emit(snackBarEventName, { message: "Text copied to clipboard", type: "success" });
    },
    close() {
      this.$emit("dialog");
      this.$emit("close");
    },
    openStartPage() {
      const routeData = this.$router.resolve("/start");
      window.open(routeData.href, "_blank");
    },
    async generateCode() {
      try {
        await this.getActivationCode(this.invitePin);
      } catch (err) {
        console.error(err);
        snackBarEventBus.$emit(snackBarEventName, {
          message: (err && err.response && err.response.data && err.response.data.message) || err,
          type: "error",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-code {
  position: relative;
  padding: 30px;

  &.fullscreen {
    padding: 30px !important;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 13px;
    border: 1px solid #e7e8f2;
  }

  &__title {
    font-size: 25px;
    color: #25233a;
    margin-top: 10px;
  }

  &__text {
    font-size: 14px;
    color: #33343d;
  }

  &__actions {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
  }

  &__field {
    background: #eff6ff;
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
    color: #2196f3;
    border-radius: 4px;
    font-size: 22px;
    cursor: pointer;
  }
}
</style>
